import { PowerPriceRecord } from "./PowerPriceRecord";

export interface PowerPriceResultSet {
    dataset: string;
    filters: string;
    limit: number;
    records: Array<PowerPriceRecord>;
    minimum: number;
    maximum: number;
    average: number;
}

export const DefaultPowerPriceResultSet: () => PowerPriceResultSet = () => ({
    dataset: "",
    filters: "",
    limit: 0,
    records: [],
    minimum: 0,
    maximum: 0,
    average: 0
});