import React from "react";
import { LoadableObject } from "./models/LoadableObject";
import { LoadableObjectState } from "./models/LoadableObjectState";
import { PowerPriceResultSet, DefaultPowerPriceResultSet } from "./models/PowerPriceResultSet";

import "./app.css";
import { PriceComponent } from "./components/PriceComponent";

const baseUrl: string = process.env.NODE_ENV === "development" ? "https://localhost:7057" : "https://api-el.oc6.dk";

function App() {
  const [resultSet, setResultSet] = React.useState<LoadableObject<PowerPriceResultSet>>({ state: LoadableObjectState.Init, data: DefaultPowerPriceResultSet() });

  React.useEffect(() => {
    if (resultSet.state === LoadableObjectState.Init) {
      loadResultSet();
    }
  }, [resultSet]);

  async function loadResultSet(): Promise<void> {
    try {
      setResultSet({
        ...resultSet,
        state: LoadableObjectState.Loading
      });

      const requestInit: RequestInit = {
        method: "GET",
        mode: "cors",
        cache: "no-cache",
        headers: {
          "Content-Type": "application/json",
        },
        redirect: "follow",
        referrerPolicy: "no-referrer",
      };

      const response: Response = await window.fetch(baseUrl + "/Power/", requestInit);

      const newResultSet: PowerPriceResultSet = await response.json();

      setResultSet({
        state: LoadableObjectState.Loaded,
        data: newResultSet
      });
    }
    catch (err) {
      console.log(err);
      setResultSet({
        state: LoadableObjectState.Error,
        data: DefaultPowerPriceResultSet()
      });
    }
  }

  function showResultSet() {
    return (
      <>
        <div className="header">
          <div className="minMaxAvgContainer">
            <div className="minMaxAvg">
              Min:{resultSet.data.minimum}
            </div>
            <div className="minMaxAvg">
              Gen:{resultSet.data.average}
            </div>
            <div className="minMaxAvg">
              Maks:{resultSet.data.maximum}
            </div>
          </div>
        </div>
        <PriceComponent resultSet={resultSet.data} />
      </>
    );

  }

  switch (resultSet.state) {
    case LoadableObjectState.Loaded: {
      return showResultSet();
    }
    case LoadableObjectState.Init:
    case LoadableObjectState.Loading: {
      return <h1>Loading</h1>;
    }
    case LoadableObjectState.Error:
    default: {
      return <h1>Error loading data</h1>;
    }
  }
}

export default App;
