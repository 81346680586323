import React from "react";
import { PowerPriceResultSet } from "../models/PowerPriceResultSet";
import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend,
} from "chart.js";
import { Bar } from "react-chartjs-2";

import "./PriceComponent.css";

ChartJS.register(
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend
);

interface IProps {
    resultSet: PowerPriceResultSet;
}

export function PriceComponent(props: IProps) {
    const options = {
        elements: {
            bar: {
                borderWidth: 2,
            },
        },
        responsive: true,
        plugins: {
            legend: {
                position: "top" as const,
            }
        },
    };

    const portraitOptions = {
        indexAxis: "y" as const,
        ...options
    };

    const landscapeOptions = {
        indexAxis: "x" as const,
        ...options
    };

    const labels = props.resultSet.records.map(x => x.hour);

    const data = {
        labels,
        datasets: [
            {
                label: "Spotpris",
                data: props.resultSet.records.map(x => x.price),
                borderColor: "rgb(53, 162, 235)",
                backgroundColor: "rgba(53, 162, 235, 0.5)",
            },
            {
                label: "Totalpris",
                data: props.resultSet.records.map(x => x.totalPrice),
                borderColor: "rgb(255, 99, 132)",
                backgroundColor: "rgba(255, 99, 132, 0.5)",
            }
        ],
    };

    return (
        <div>
            <div className="portrait">
                <Bar
                    width={100}
                    height={props.resultSet.records.length * 20}
                    options={portraitOptions}
                    data={data} />
            </div>
            <div className="landscape">
                <Bar
                    height={100}
                    options={landscapeOptions}
                    data={data} />
            </div>
        </div>
    );
}